<template>
  <PopUp></PopUp>
  <Header></Header>
  <router-view/>
  <CookieBar />
  <Footer></Footer>
</template>

<script setup>
  import Header from '@/components/HeaderTRK' 
  import Footer from '@/components/FooterTRK'
  import PopUp from '@/components/PopUp.vue'
  import CookieBar from '@/components/CookieBar.vue'
</script>

<style>

</style>
