<template>
    <div class="w-full mx-auto bg-gris text-white footer-links">
        <div class="container mx-auto px-6 lg:px-4 flex justify-between items-end ">
            <div class="">
                <router-link to="/">
                    <img src="@/assets/img/logo.png" alt="TRF" class="h-28" />
                </router-link>
            </div>
            <div class="flex gap-2">
                <a href="https://www.facebook.com/profile.php?id=61555214099755" target="_blank">
                                <img src="@/assets/img/facebook.png" alt="TRF" />
                            </a>
                <a href="https://x.com/TRKGolf?t=VPr5pKM-ykpGxiDZkg0YGQ&s=08" target="_blank">
                                <img src="@/assets/img/x.png" alt="TRF" />
                            </a>
                <a href="https://www.instagram.com/trkgolf/" target="_blank">
                                <img src="@/assets/img/instagram.png" alt="TRF" />
                            </a>
                <a href="https://www.youtube.com/channel/UCqAnHyyNx0pvubKoP0rkdCw" target="_blank">
                                <img src="@/assets/img/youtube.png" alt="TRF" />
                            </a>
                <a href="https://www.tiktok.com/@trk_golf" target="_blank">
                                <img src="@/assets/img/tiktok.png" alt="TRF" />
                            </a>
                <a href="https://api.whatsapp.com/send?phone=622268193" target="_blank">
                                <img src="@/assets/img/whatsapp.png" alt="TRF" />
                            </a>
            </div>
            <div class="links-internos">
                <div>  
                    <router-link to="/nuestra-mision">NUESTRA MISIÓN</router-link>
                </div>
                <div>  
                    <a :href="url" target="_blank">MANUAL DE USUARIO</a>
                </div>
                <div>
                    <router-link to="/contacto">CONTACTO</router-link>
                </div>
                <div>&nbsp;</div>
            </div>
            <div class="links-internos">
                <div>
                    <router-link to="/aviso-legal">AVISO LEGAL</router-link>
                </div>
                <div>
                    <router-link to="/politica-privacidad">POLÍTICA DE PRIVACIDAD</router-link>
                </div>
                <div>
                    <router-link to="/cookies">POLÍTICA DE COOKIES</router-link>
                </div>
                <div>
                    <router-link to="/condiciones-generales">CONDICIONES GENERALES DE CONTRATACIÓN</router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full mx-auto bg-verde text-white text-center footer-legales">
        <div class="container mx-auto px-4">
            <div>© 2023 TRK Golf</div>
        </div>
    </div>
</template>

<script setup>
    const url       = process.env.VUE_APP_BASE + 'files/manual_usuario.pdf'
</script>

<style scoped>
.footer-links {
    padding: 75px 0 90px;
    font-family: 'Roboto', sans-serif;
    font-size: 19px;
    font-weight: 100;
    line-height: 33px;
    letter-spacing: 0.76px;
}

.links-internos div:not(:last-child) {
    margin-bottom: 10px;
}

.links-internos div:last-child {
    margin-bottom: -10px;
}

.footer-legales {
    padding: 35px 0;
    font-size: 19px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.76px;
}

.footer-legales div:first-child {
    margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
    .footer-links {
        padding: 55px 0 55px;
        font-size: 14px;
    }
    .footer-legales {
        padding: 25px 0 10px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    .footer-links {
        padding: 55px 0 55px;
        font-size: 12px;
    }
    .footer-legales div:first-child {
        margin-bottom: 0px;
    }
    .footer-legales {
        padding: 15px 0 10px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 640px) {
    .footer-links>div {
        flex-flow: wrap;
        align-items: center;
    }
    .footer-links>div>div {
        width: 50%;
    }
    .footer-links>div>div:nth-child(1),
    .footer-links>div>div:nth-child(2) {
        margin-bottom: 25px;
    }
    .footer-links>div>div:nth-child(1) img {
        max-width: 50px;
        height: auto;
    }
}
</style>